#shuts-widget-chat .shuts-chat {
  &-btn {
    background: var(--buttonBg);
    color: var(--buttonText);
    border: none;
    width: 48px;
    height: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    outline: none;
  }

  &-input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    &--sending {
      opacity: 0.5;
    }
  }

  &-text-input {
    width: calc(100% - 48px);
    height: 48px;
    margin-right: 15px;
    border: none;
    border-radius: 10px;
    padding: 0 15px;
    font-size: 16px;
    outline: none;
    background-color: var(--inputBg);
    color: var(--inputText);
    z-index: 2;
    position: relative;
  }

  &-signature {
    font-size: 12px;
    color: var(--signature);
    margin: 15px auto 0;

    svg {
      margin-left: 5px;
      * {
        fill: var(--signature);
        fill-opacity: 1;
      }
    }
  }
}
